import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import MetadataBuyDealerUI from "./MetadataBuyDealer";
import useStyles from "./MetadataBuyDealer.styles";
import {
  getSupplierRejectReason,
  clearSupplierRejectReason,
  putSupplierReject,
  clearSupplierReject,
  putSupplierAprove,
  clearSupplierAprove,
} from "../../reducers/dealer.action";

const errorEmptyMsgs = {
  reason: "* Selecciona un motivo de rechazo !",
  amount: "* Ingresa un monto de compra !",
  comment: "* Ingresa un comentario !",
};

function MetadataBuyDealer(props) {
  const { creditLineId, accountId, purchaseId, voucherId, supplierId } =
    props.match.params;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataVouchersClient, setDataVouchersClient] = useState([]);
  const [voucherData, setVoucherData] = useState({});
  const [metadata, setMetadata] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [rejectionModal, setRejectionModal] = useState({
    showReject: false,
    id: "",
  });
  const [values, setValues] = useState({
    amount: "",
    reason: "",
    comment: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    amount: "",
    reason: "",
    comment: "",
  });

  const { saveVouchers } = useSelector((state) => state.saveVouchersReducer);

  const { supplierRejectReasonData, supplierRejectData, supplierAproveData } =
    useSelector((state) => state.supplierReducer);

  useEffect(() => {
    dispatch(getSupplierRejectReason());
  }, []);

  useEffect(() => {
    if (Object.keys(saveVouchers).length !== 0) {
      setDataVouchersClient(saveVouchers);
    }
  }, [saveVouchers]);

  useEffect(() => {
    if (Object.keys(dataVouchersClient).length !== 0) {
      const filtered = dataVouchersClient.filter(
        (item) => item.id === voucherId
      );

      if (filtered) {
        setVoucherData(filtered[0]);
      }
    }
  }, [dataVouchersClient, voucherId]);

  useEffect(() => {
    try {
      const transformMetadata = voucherData?.metadata
        ? JSON.parse(voucherData.metadata)
        : null;
      if (transformMetadata) {
        setMetadata(transformMetadata);
      }
    } catch (error) {
      console.error("Failed to parse metadata", error);
    }
  }, [voucherData]);

  useEffect(() => {
    if (Object.keys(supplierRejectData).length !== 0) {
      dispatch(clearSupplierReject());
      dispatch(clearSupplierRejectReason());
      setValues({
        amount: "",
        reason: "",
        comment: "",
      });
      setErrorMessages({
        amount: "",
        reason: "",
        comment: "",
      });
      setRejectionModal({
        showReject: false,
        id: "",
      });
      history.replace(`/buy-dealer`);
    }
  }, [supplierRejectData]);

  useEffect(() => {
    if (Object.keys(supplierAproveData).length !== 0) {
      dispatch(clearSupplierRejectReason());
      dispatch(clearSupplierAprove());

      history.replace(`/buy-dealer`);
    }
  }, [supplierAproveData]);

  const toReturn = () => {
    dispatch(clearSupplierRejectReason());
    history.replace(
      `/buy-dealer/detail/${creditLineId}/${accountId}/${purchaseId}`
    );
  };

  const handleChangeInput = (prop) => (event) => {
    let { value } = event.target;
    setValues({ ...values, [prop]: value });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const rejected = (voucherId) => {
    let hasError = false;
    const errors = {};

    // Empty validator error
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        hasError = true;
        errors[k] = errorEmptyMsgs[k];
      } else {
        errors[k] = "";
      }
    });

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    let body = {
      reject_reason: values.reason,
      comment: values.comment,
      amount: parseFloat(values.amount),
      metadata: JSON.stringify(metadata),
    };

    dispatch(putSupplierReject(creditLineId, purchaseId, voucherId, body));
  };

  const validate = (voucherId, ticketId) => {
    let body = {
      amount: parseFloat(metadata?.TotalAmount),
      metadata: JSON.stringify(metadata),
      ticket_id: ticketId,
      supplier_id: supplierId,
    };

    dispatch(putSupplierAprove(creditLineId, purchaseId, voucherId, body));
  };

  return (
    <MetadataBuyDealerUI
      classes={classes}
      toReturn={toReturn}
      dataVouchersClient={dataVouchersClient}
      values={values}
      handleChangeInput={handleChangeInput}
      errorMessages={errorMessages}
      rejectReason={
        Object.keys(supplierRejectReasonData).length !== 0
          ? supplierRejectReasonData?.data
          : []
      }
      rejectionModal={rejectionModal}
      setRejectionModal={setRejectionModal}
      rejected={rejected}
      validate={validate}
      voucherData={voucherData}
      metadata={metadata}
      setMetadata={setMetadata}
      isEditable={isEditable}
      setIsEditable={setIsEditable}
    />
  );
}

export default MetadataBuyDealer;
