import axios from "axios";
import { appService } from "./services/app.service";

const client = (() =>
  axios.create({
    baseURL: appService.config.paths.api.basePath,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
  }))();

client.interceptors.request.use((config) => {
  const token = localStorage.getItem("firebaseToken");

  if (token) {
    config.headers.Authorization = token;
  } else {
    config.headers.Authorization = null;
  }
  return config;
});

// Intercept all responses
client.interceptors.response.use(
  (response) => {
    console.log(
      `%c ${response.status} - ${response.config}:`,
      "color: #008000; font-weight: bold",
      response
    );
    return response;
  },

  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        if (window.location.pathname != "/") {
          window.location.replace(
            "/?previous_path=" + encodeURIComponent(window.location.pathname)
          );
        }
        return;
      } else if (error.response.status === 403) {
        alert(
          "No tienes permisos suficientes para ejecutar la acción solicitada"
        );
        window.location.replace("/");
        return;
      }
      console.log(
        `%c ${error.response.status} - ${error.response.config}:`,
        "color: #a71d5d; font-weight: bold",
        error.response
      );
    }

    return Promise.reject(error);
  }
);

export default client;
