import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import MetadataReviewVouchersUI from "./MetadataReviewVouchers";
import useStyles from "./MetadataReviewVouchers.styles";
import {
  getSupplierRejectReason,
  clearSupplierRejectReason,
} from "../../reducers/dealer.action";
import {
  getVouchersWeekly,
  clearGetVouchersWeekly,
  putVouchers,
  clearPutVouchers,
} from "../../reducers/reviewVouchers.action";

const errorEmptyMsgs = {
  amount: "* Ingresa un monto de compra !",
  reason: "* Selecciona un motivo de rechazo !",
  comment: "* Ingresa un comentario !",
};

function MetadataReviewVouchers(props) {
  const { creditLineId, accountId, voucherId, supplierId } = props.match.params;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataVouchersClient, setDataVouchersClient] = useState([]);
  const [voucherData, setVoucherData] = useState({});
  const [metadata, setMetadata] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [dealerList, setDealerList] = useState([]);
  const [rejectionModal, setRejectionModal] = useState({
    showReject: false,
    id: "",
    ticketId: "",
  });
  const [values, setValues] = useState({
    amount: "",
    reason: "",
    comment: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    amount: "",
    reason: "",
    comment: "",
  });

  const { getVouchersWeeklyData, putVouchersData } = useSelector(
    (state) => state.reviewVoucherReducer
  );

  const { suppliersData, supplierRejectReasonData } = useSelector(
    (state) => state.supplierReducer
  );

  useEffect(() => {
    dispatch(getVouchersWeekly(creditLineId));
    dispatch(getSupplierRejectReason());
  }, [creditLineId]);

  useEffect(() => {
    if (Object.keys(getVouchersWeeklyData).length !== 0) {
      if (getVouchersWeeklyData?.data && getVouchersWeeklyData.data) {
        setDataVouchersClient(getVouchersWeeklyData.data);
      }
    }
  }, [getVouchersWeeklyData]);

  useEffect(() => {
    if (Object.keys(dataVouchersClient).length !== 0) {
      const voucher = dataVouchersClient
        .flatMap((week) => week.vouchers)
        .find((voucher) => voucher.voucher_id === voucherId);

      if (voucher) {
        setVoucherData(voucher);
      }
    }
  }, [dataVouchersClient, voucherId]);

  useEffect(() => {
    try {
      const transformMetadata = voucherData?.metadata
        ? JSON.parse(voucherData.metadata)
        : null;
      if (transformMetadata) {
        setMetadata(transformMetadata);
      }
    } catch (error) {
      console.error("Failed to parse metadata", error);
    }
  }, [voucherData]);

  useEffect(() => {
    if (Object.keys(putVouchersData).length !== 0) {
      dispatch(clearPutVouchers());
      dispatch(clearGetVouchersWeekly());
      dispatch(clearSupplierRejectReason());
      setValues({
        amount: "",
        reason: "",
        comment: "",
      });
      setErrorMessages({
        amount: "",
        reason: "",
        comment: "",
      });
      setRejectionModal({
        showReject: false,
        id: "",
        ticketId: "",
      });
      history.replace(`/review-voucher/detail/${creditLineId}/${accountId}`);
    }
  }, [putVouchersData]);

  const toReturn = () => {
    dispatch(clearGetVouchersWeekly());
    dispatch(clearSupplierRejectReason());
    history.replace(`/review-voucher/detail/${creditLineId}/${accountId}`);
  };

  const handleChangeInput = (prop) => (event) => {
    let { value } = event.target;
    setValues({ ...values, [prop]: value });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const rejected = (voucherId, ticketId) => {
    let hasError = false;
    const errors = {};

    // Empty validator error
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        hasError = true;
        errors[k] = errorEmptyMsgs[k];
      } else {
        errors[k] = "";
      }
    });

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    let body = {
      amount: parseFloat(values.amount),
      review_status: "REJECTED",
      reject_reason: values.reason,
      reject_comment: values.comment,
      supplier_id: supplierId,
      metadata: JSON.stringify(metadata),
      ticket_id: ticketId,
    };

    dispatch(putVouchers(voucherId, body));
  };

  const validate = (voucherId, ticketId) => {
    let body = {
      amount: parseFloat(metadata?.TotalAmount),
      review_status: "APPROVED",
      reject_reason: "",
      reject_comment: "",
      supplier_id: supplierId,
      metadata: JSON.stringify(metadata),
      ticket_id: ticketId,
    };

    dispatch(putVouchers(voucherId, body));
  };

  return (
    <MetadataReviewVouchersUI
      classes={classes}
      toReturn={toReturn}
      dataVouchersClient={dataVouchersClient}
      values={values}
      handleChangeInput={handleChangeInput}
      errorMessages={errorMessages}
      rejectReason={
        Object.keys(supplierRejectReasonData).length !== 0
          ? supplierRejectReasonData?.data
          : []
      }
      rejectionModal={rejectionModal}
      setRejectionModal={setRejectionModal}
      rejected={rejected}
      validate={validate}
      voucherData={voucherData}
      metadata={metadata}
      setMetadata={setMetadata}
      isEditable={isEditable}
      setIsEditable={setIsEditable}
    />
  );
}

export default MetadataReviewVouchers;
