import React from "react";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button, MenuItem, Modal, IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import PageContainer from "../../components/PageContainer";
import Box from "../../components/Layout/Box";
import Text from "../../components/Text";
import TableMetadataBuyDealer from "../../components/TableMetadataBuyDealer";
import TextInput from "../../components/TextInput";

function MetadataBuyDealer({
  classes,
  toReturn,
  values,
  handleChangeInput,
  errorMessages,
  rejectReason,
  rejectionModal,
  setRejectionModal,
  rejected,
  validate,
  voucherData,
  metadata,
  setMetadata,
  isEditable,
  setIsEditable,
}) {
  return (
    <PageContainer>
      <Box onClick={toReturn} className={classes.controls}>
        <ArrowBackIcon />
        <Text className={classes.textReturn}>Regresar</Text>
      </Box>
      <Box className={classes.generalContainer}>
        <img src={voucherData?.img_url} className={classes.imageTicket} />
        <TableMetadataBuyDealer
          metadata={metadata}
          setMetadata={setMetadata}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          voucherData={voucherData}
        />
      </Box>
      {voucherData?.revision_status === "PENDING" && (
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.buttonDecline}
            variant="outlined"
            onClick={() =>
              setRejectionModal({
                showReject: true,
                id: voucherData?.id,
                ticketId: metadata?.ReceiptNumber,
              })
            }
            disabled={isEditable === false ? false : true}
          >
            Rechazar
          </Button>
          <Button
            className={classes.buttonValidate}
            variant="contained"
            color="secondary"
            onClick={() => validate(voucherData?.id, metadata?.ReceiptNumber)}
            disabled={isEditable === false ? false : true}
          >
            Validar ticket
          </Button>
        </Box>
      )}
      <Modal
        open={rejectionModal.showReject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalRejection}
      >
        <Box className={classes.modalContainerRejected}>
          <IconButton
            className={classes.buttonClose}
            onClick={() =>
              setRejectionModal({
                showReject: false,
                id: "",
              })
            }
          >
            <Close />
          </IconButton>
          <Text>Rechazo de comprobante</Text>
          <TextInput
            className={classes.formElement}
            label="Motivo de rechazo"
            variant="standard"
            select
            value={values.reason}
            onChange={handleChangeInput("reason")}
            helperText={errorMessages.reason}
            error={errorMessages.reason !== "" ? true : false}
          >
            {rejectReason.length !== 0 ? (
              rejectReason.map((reject) => (
                <MenuItem value={reject.name}>
                  {reject.description_spanish}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={""}>Sin datos</MenuItem>
            )}
          </TextInput>
          <TextInput
            className={classes.formElement}
            label="Monto de rechazo de compra"
            variant="standard"
            type="number"
            value={values.amount}
            onChange={handleChangeInput("amount")}
            helperText={errorMessages.amount}
            error={errorMessages.amount !== "" ? true : false}
          />
          <TextInput
            className={classes.formElement}
            label="Comentarios"
            variant="standard"
            multiline={true}
            value={values.comment}
            onChange={handleChangeInput("comment")}
            helperText={errorMessages.comment}
            error={errorMessages.comment !== "" ? true : false}
          />
          <Button
            className={classes.buttonModal}
            variant="contained"
            color="secondary"
            onClick={() => rejected(rejectionModal.id, rejectionModal.ticketId)}
            disabled={
              values.reason !== "" &&
              values.comment !== "" &&
              values.amount !== ""
                ? false
                : true
            }
          >
            Rechazar
          </Button>
        </Box>
      </Modal>
    </PageContainer>
  );
}

export default MetadataBuyDealer;
