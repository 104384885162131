import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PageContainer from "../../components/PageContainer";
import { Box } from "../../components/Layout";
import MissingDataAlert from "../../components/MissingDataAlert/MissingDataAlert";
import Text from "../../components/Text";
import { getFindShop, getCreditLine } from "../Credit/Payment/paymentActions";
import * as ACTIONS from "../Credit/Payment/actionTypes";
import useStyles from "./CreditExtensionForm.style";
import SectionTitle from "../../components/SectionTitle";
import Button from "../../components/Button";
import { postParticularCreditLineExtensionRequest } from "../../reducers/creditline.action";
import CustomSnackBar from "../../components/CustomSnackBar/CustomSnackBar";
import I18n from "../../utils/i18n";

const CreditExtensionForm = () => {
  const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "green",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "white",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "red",
        },
        "&:hover fieldset": {
          borderColor: "yellow",
        },
        "&.Mui-focused fieldset": {
          borderColor: "white",
        },
      },
    },
  })(TextField);

  const dispatch = useDispatch();
  const classes = useStyles();

  const { error, shops } = useSelector((state) => state.registersState);
  const { error: extensionError } = useSelector(
    (state) => state.creditLineReducer
  );

  const [values, setValues] = useState({
    shopSelected: "",
    amount: "",
    interestRate: "",
    installment: "",
    minInstallment: "",
    maxInstallment: "",
    evaluationType: "",
  });

  const [inputValue, setInputVale] = useState("");
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [creatingExtension, setCreatingExtension] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [errorMessages, setErrorMessages] = useState({
    shopSelected: "",
    amount: "",
    interestRate: "",
    installment: "",
    minInstallment: "",
    maxInstallment: "",
    evaluationType: "",
  });
  const errorMsgs = {
    shopSelected: "* Seleccione la tienda !",
    amount: "* Ingrese el monto !",
    interestRate: "* Ingrese el interés de la línea de crédito !",
    installment: "* Ingrese la cuota de la línea de crédito !",
    minInstallment: "* Ingrese la cuota mínima de la línea de crédito !",
    maxInstallment: "* Ingrese la cuota máxima de la línea de crédito !",
    evaluationType: "* Ingrese el tipo de evaluación de la línea de crédito !",
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};

    const requiredFields = [
      "shopSelected",
      "amount",
      "interestRate",
      "installment",
      "minInstallment",
      "maxInstallment",
      "evaluationType",
    ];
    requiredFields.forEach((field) => {
      if (!values[field] || values[field] === 0) {
        hasError = true;
        errors[field] = errorMsgs[field];
      } else {
        errors[field] = "";
      }
    });

    if (values.amount % 500 !== 0) {
      hasError = true;
      errors.amount = "* El monto del crédito debe ser múltiplo de 500 !";
    }
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }
    const creditLineId = values.shopSelected.credit_line_id;
    setCreatingExtension(true);
    const body = {
      credit_line_id: creditLineId,
      amount: Number(values.amount),
      interest_rate: Number(values.interestRate),
      installment: Number(values.installment),
      min_installment: Number(values.minInstallment),
      max_installment: Number(values.maxInstallment),
      banner_active: values.evaluationType === "AUTOMATIC" ? true : false,
    };
    dispatch(postParticularCreditLineExtensionRequest(body)).then(
      handleOpenSnackbar()
    );
  };

  const handleSelectShop = () => (event) => {
    handleChange("shopSelected")(event);
    dispatch(getCreditLine(event.target.value.credit_line_id));
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenSnackbar(false);
  };
  const handleOpenSnackbar = () => {
    setTimeout(() => {
      setIsOpenSnackbar(true);
    }, 500);
    setCreatingExtension(false);
    setTimeout(() => {
      window.location.replace("/extensions");
    }, 4000);
  };
  useEffect(() => {
    if (extensionError?.POST_PARTICULAR_EXTENSION_REQUEST) {
      const response =
        extensionError.POST_PARTICULAR_EXTENSION_REQUEST.response;

      if (response) {
        if (response.status === 200) {
          setIsError(false);
        } else if (response.status === undefined) {
          setIsError(true);
        } else {
          setIsError(true);
        }
      }
    }
  }, [extensionError?.POST_PARTICULAR_EXTENSION_REQUEST]);

  const shopList =
    shops && shops.length > 0
      ? shops.map((shop, i) => (
          <MenuItem value={shop}>
            {shop.personhood_name}/{shop.shopkeeper_name}
          </MenuItem>
        ))
      : "";

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale("");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  return (
    <PageContainer>
      <Box width="100%">
        <CustomSnackBar
          open={isOpenSnackbar}
          onClose={handleCloseSnackbar}
          msgType={isError ? "error" : "success"}
          msg={
            isError
              ? extensionError?.POST_PARTICULAR_EXTENSION_REQUEST?.response
                  ?.data?.readable_message || "Ocurrió un error"
              : "Extensión aplicada con éxito"
          }
        />
        <Link to="/extensions" className={classes.controls}>
          <ArrowBackIcon />
          <Text>Regresar</Text>
        </Link>

        <Box className={classes.mainPanel}>
          <SectionTitle>Extensión a tienda</SectionTitle>
        </Box>

        <Box>
          {nothingFoundNoticeShown && (
            <MissingDataAlert status="BAD" text="No se encontraron tiendas" />
          )}
        </Box>

        <Box>
          <Text variant="h4" className={classes.textBlue}>
            Nombre, ID o teléfono de la tienda
          </Text>
        </Box>

        <Box className={classes.searchContainer}>
          <InputBase
            value={inputValue}
            onChange={(event) => {
              setInputVale(event.target.value);
              dispatch({
                type: ACTIONS.SET_STATE,
                label: "searchString",
                value: event.target.value,
              });
            }}
            className={classes.input}
            placeholder="Nombre, ID o teléfono de la tienda"
            inputProps={{ "aria-label": "search user" }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                dispatch(getFindShop());
              }
            }}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            onClick={() => {
              dispatch(getFindShop());
            }}
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Box>
        <FormHelperText>{errorMessages.shopSelected}</FormHelperText>

        {shops && shops.length >= 1 ? (
          <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>
              Elige una tienda
            </Text>
            <Select
              displayEmpty
              style={{ display: "flex" }}
              labelId="demo-simple-select-label"
              id="advisor"
              value={values.shopSelected}
              onChange={handleSelectShop()}
            >
              <MenuItem value="" disabled>
                Selecciona una tienda
              </MenuItem>
              {shopList}
            </Select>
          </Box>
        ) : (
          <Box />
        )}
        {values.shopSelected ? (
          <Box>
            <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                id="custom-css-standard-input"
                label="Responsable de la tienda"
                defaultValue=""
                value={values.shopSelected.shopkeeper_name}
                InputLabelProps={{ shrink: true }}
              />
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Teléfono del responsable"
                id="custom-css-outlined-input"
                defaultValue="No viene del WS"
                value={values.shopSelected.shopkeeper_phone_number}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label={`${i18n.nacionatilityDocument} del responsable de la tienda`}
                id="custom-css-outlined-input"
                defaultValue=""
                value={values.shopSelected.shopkeeper_curp}
                InputLabelProps={{ shrink: true }}
              />
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Dirección de la tienda"
                id="custom-css-standard-input"
                defaultValue=""
                value={`${values.shopSelected.street_name} ${values.shopSelected.street_number} ${values.shopSelected.apartment_number} CP ${values.shopSelected.zip_code} - ${values.shopSelected.neighborhood}, ${values.shopSelected.delegation}, ${values.shopSelected.state}`}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        ) : (
          <Box />
        )}
        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            Monto
          </Text>
          <Input
            id="credit-amount"
            style={{ display: "flex" }}
            value={values.amount}
            type="number"
            onChange={handleChange("amount")}
            placeholder="De $5,000 a $20,000"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <FormHelperText>{errorMessages.amount}</FormHelperText>
        </Box>
        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            Tasa de interés mensual
          </Text>
          <Input
            id="interest-rate-number"
            style={{ display: "flex" }}
            value={values.interestRate}
            type="number"
            onChange={handleChange("interestRate")}
            placeholder="10%"
            inputProps={{ maxLength: 5 }}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
          />
          <FormHelperText>{errorMessages.interestRate}</FormHelperText>
        </Box>
        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            Cuota
          </Text>
          <Input
            id="credit-amount"
            style={{ display: "flex" }}
            value={values.installment}
            type="number"
            onChange={handleChange("installment")}
            placeholder="De $5,000 a $20,000"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <FormHelperText>{errorMessages.installment}</FormHelperText>
        </Box>
        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            Cuota mínima
          </Text>
          <Input
            id="min-credit-amount"
            style={{ display: "flex" }}
            value={values.minInstallment}
            type="number"
            onChange={handleChange("minInstallment")}
            placeholder="De $5,000 a $20,000"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <FormHelperText>{errorMessages.minInstallment}</FormHelperText>
        </Box>
        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            Cuota máxima
          </Text>
          <Input
            id="credit-max-amount"
            style={{ display: "flex" }}
            value={values.maxInstallment}
            type="number"
            onChange={handleChange("maxInstallment")}
            placeholder="De $5,000 a $20,000"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <FormHelperText>{errorMessages.maxInstallment}</FormHelperText>
        </Box>
        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            Mostrar banner de amplicación
          </Text>
          <Select
            displayEmpty
            style={{ display: "flex" }}
            id="evaluation-type"
            value={values.evaluationType}
            onChange={handleChange("evaluationType")}
          >
            <MenuItem value="" disabled>
              Mostrar banner de ampliación
            </MenuItem>
            <MenuItem value="AUTOMATIC">Sí</MenuItem>
            <MenuItem value="MANUAL">No</MenuItem>
          </Select>
          <FormHelperText>{errorMessages.evaluationType}</FormHelperText>
        </Box>
        <Box style={{ width: "35%" }}>
          <FormControl className={classes.formStyles}>
            <Box className={classes.buttonContainer}>
              <Button
                variant="outlined"
                className={classes.button}
                style={{ backgroundColor: "#0253cc", color: "white" }}
                disabled={creatingExtension}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Crear ampliación
              </Button>
            </Box>
            <Box style={{ display: creatingExtension ? "block" : "none" }}>
              <CircularProgress />
            </Box>
          </FormControl>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default CreditExtensionForm;
