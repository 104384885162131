import React from "react";

import { Button, MenuItem } from "@material-ui/core";

import Box from "../../components/Layout/Box";
import Text from "../../components/Text";
import TextInput from "../../components/TextInput";
import icons from "../../assets/icons";
import moment from "moment";

function CardVouchers({
  classes,
  data,
  values,
  handleChangeInput,
  setZoomModal,
  dealerList,
  viewDetail,
  transformMetadata,
}) {
  return (
    <>
      <Box className={classes.containerTicket}>
        <img src={data?.img_url} className={classes.imageContainer} />
        <img
          src={icons.zoomIcon}
          className={classes.zoomImage}
          onClick={() =>
            setZoomModal({
              show: true,
              image: data?.img_url,
            })
          }
        />
        {data.revision_status === "PENDING" && (
          <>
            <TextInput
              className={classes.formElement}
              label="Distribuidor"
              variant="standard"
              select
              value={values.dealer}
              onChange={handleChangeInput("dealer")}
            >
              {dealerList.length !== 0 &&
                dealerList.map((dealer) => (
                  <MenuItem value={dealer.id}>{dealer.name}</MenuItem>
                ))}
            </TextInput>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`Monto`}</Text>
              <Text className={classes.textInfo}>
                {data?.metadata
                  ? transformMetadata(data?.metadata, "total_amount")
                  : "Sin datos"}
              </Text>
            </Box>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`Fecha de compra`}</Text>
              <Text className={classes.textInfo}>
                {data?.metadata
                  ? transformMetadata(data?.metadata, "date")
                  : "Sin datos"}
              </Text>
            </Box>
            <Button
              className={classes.buttonViewDetail}
              variant="contained"
              color="secondary"
              onClick={() => viewDetail(data?.id)}
              disabled={!values.dealer && data.revision_status === "PENDING"}
            >
              Ver detalle
            </Button>
          </>
        )}
        {data.revision_status === "REJECTED" && (
          <div className={classes.containerStatus}>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`Estatus`}</Text>
              <Box className={classes.containerRejected}>
                <Text className={classes.statusText}>{`Rechazado`}</Text>
              </Box>
            </Box>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`Fecha de ejecución`}</Text>
              <Text className={classes.textInfo}>
                {data?.created_at
                  ? moment(data?.created_at).format("YYYY-MM-DD")
                  : "Sin datos"}
              </Text>
            </Box>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`# de ticket`}</Text>
              <Text className={classes.textInfo}>
                {data?.metadata
                  ? transformMetadata(data?.metadata, "receipt_number")
                  : "Sin datos"}
              </Text>
            </Box>
          </div>
        )}
        {data.revision_status === "APPROVED" && (
          <div className={classes.containerStatus}>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`Estatus`}</Text>
              <Box className={classes.containerApproved}>
                <Text className={classes.statusText}>{`Aceptado`}</Text>
              </Box>
            </Box>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`Fecha de ejecución`}</Text>
              <Text className={classes.textInfo}>
                {data?.created_at
                  ? moment(data?.created_at).format("YYYY-MM-DD")
                  : "Sin datos"}
              </Text>
            </Box>
            <Box className={classes.infoContainer}>
              <Text className={classes.titleInfo}>{`# de ticket`}</Text>
              <Text className={classes.textInfo}>
                {data?.metadata
                  ? transformMetadata(data?.metadata, "receipt_number")
                  : "Sin datos"}
              </Text>
            </Box>
          </div>
        )}
      </Box>
    </>
  );
}

export default CardVouchers;
