import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DetailBuyDealerUI from "./DetailBuyDealer";
import useStyles from "./DetailBuyDealer.styles";
import { getShokeeperDetailById } from "../Shop/ShopPage.actions";
import { getCreditDetail } from "../Shop/SubMenu/CreditInformation/CreditInformation.actions";

function DetailBuyDealer(props) {
  const { creditLineId, accountId, purchaseId } = props.match.params;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [subMenu, setSubMenu] = useState("buy_dealer");
  const [zoomModal, setZoomModal] = useState({
    show: false,
    image: "",
  });

  const { shokeeper_detail } = useSelector((state) => state.shopState);

  const { credit_line_shop_detail } = useSelector(
    (state) => state.creditInformationState
  );

  const { saveVouchers } = useSelector((state) => state.saveVouchersReducer);

  const { saveSuppliers } = useSelector((state) => state.saveSuppliersReducer);

  const { saveAmounts } = useSelector((state) => state.saveAmountsReducer);

  useEffect(() => {
    dispatch(getShokeeperDetailById(accountId));
    dispatch(getCreditDetail(creditLineId));
  }, [accountId, creditLineId]);

  return (
    <DetailBuyDealerUI
      classes={classes}
      shokeeper_detail={shokeeper_detail}
      credit_line_shop_detail={credit_line_shop_detail}
      subMenu={subMenu}
      setSubMenu={setSubMenu}
      zoomModal={zoomModal}
      setZoomModal={setZoomModal}
      saveVouchers={saveVouchers}
      saveSuppliers={saveSuppliers}
      saveAmounts={Object.keys(saveAmounts).length !== 0 ? saveAmounts : {}}
      purchaseId={purchaseId}
      creditLineId={creditLineId}
      accountId={accountId}
    />
  );
}

export default DetailBuyDealer;
