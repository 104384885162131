import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutPageC from "./containers/About/AboutPage";
import App from "./containers/App";
import DashboardPageC from "./containers/Dashboard/DashboardPage";
import HomePageC from "./containers/Home/HomePage";
import NotFoundPageC from "./containers/NotFound/NotFoundPage";
import { RegistersPage } from "./containers/Registers/RegistersPage";
import { CustomersPage } from "./containers/Customers/CustomersPage";
import ShopsPage from "./containers/Shops/ShopsPage";

import CreditPage from "./containers/Credit/CreditPage";
import CreditCreatePage from "./containers/Credit/Create/CreditCreatePage";
import CreditUpdatePage from "./containers/Credit/Update/CreditUpdatePage";
import CreditDetailPage from "./containers/Credit/Detail/CreditDetailPage";

import ExpressExtension from "./containers/ExpressExtension";

import { CustomerDetails } from "./containers/CustomerDetails/CustomerDetails";
import { LoginPage } from "./containers/Login/LoginPage";
import { CustomerTransactions } from "./containers/CustomerTransactions/CustomerTransactions";
import SettlementDetails from "./containers/Shops/settlements/SettlementDetails";
import CollectionsDetails from "./containers/Shops/collections/CollectionsDetails";
import ShopTransactions from "./containers/Shops/transactions/ShopTransactions";

import TreasuryPage from "./containers/Treasury/TreasuryPage";
import ShopPaymentsPage from "./containers/Credit/Payment/PaymentPage";
import DisbursementsPage from "./containers/Credit/Disbursement/DisbursementPage";
import DeletePage from "./containers/Credit/Delete/DeletePage";
import ShopPage from "./containers/Shop/ShopPage";
import SubMenuPage from "./containers/Shop/SubMenu/SubMenuPage";
import ReportsPage from "./containers/Reports/Reports";
import WholesalerPage from "./containers/Wholesalers/CreateWholesaler";
import BureauDocumentsPage from "./containers/Credit/BureauDocuments/BureauDocuments";
import WareHouse from "./containers/WareHouse/WareHouse";
import BuyDealer from "./containers/BuyDealer";
import DetailBuyDealer from "./containers/DetailBuyDealer";
import DisbursementDealer from "./containers/DisbursementDealer";
import LandingPage from "./containers/LandingPage";
import CreditExtensions from "./containers/CreditExtensions/CreditExtensions";
import ExtensionDetails from "./containers/ExtensionDetails/ExtensionDetails";
import ReviewVoucher from "./containers/ReviewVoucher";
import DetailReviewVouchers from "./containers/DetailReviewVouchers";
import CreditExtensionForm from "./containers/CreditExtensionForm/CreditExtensionForm";
import MatiVerification from "./containers/MatiVerification";
import MetadataReviewVouchers from "./containers/MetadataReviewVouchers";
import MetadataBuyDealer from "./containers/MetadataBuyDealer";

const RouterR = () => (
  <Router>
    <App>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route path="/customers/:customerID" component={CustomerTransactions} />
        <Route exact path="/dashboard" component={DashboardPageC} />
        <Route exact path="/about" component={AboutPageC} />
        <Route exact path="/home" component={HomePageC} />
        <Route exact path="/register-landing" component={LandingPage} />
        <Route exact path="/registers" component={RegistersPage} />
        <Route
          path="/registers/:customerType/:customerID"
          component={CustomerDetails}
        />
        <Route path="/customers" component={CustomersPage} />
        <Route path="/mati-verification" component={MatiVerification} />

        <Route exact path="/shops/:resourceType" component={ShopsPage} />

        <Route exact path="/credits" component={CreditPage} />
        <Route exact path="/credits/create" component={CreditCreatePage} />
        <Route
          exact
          path="/credits/:customerID/update"
          component={CreditUpdatePage}
        />
        <Route
          exact
          path="/credits/:customerID/detail"
          component={CreditDetailPage}
        />

        <Route exact path="/express" component={ExpressExtension} />

        <Route
          exact
          path="/shops/settlements/:resourceId"
          component={SettlementDetails}
        />
        <Route
          exact
          path="/shops/collections/:resourceId"
          component={CollectionsDetails}
        />

        <Route
          exact
          path="/shops/settlements/:resourceId/transactions/:shopkeeperId"
          component={ShopTransactions}
        />
        <Route
          exact
          path="/shops/collections/:resourceId/transactions/:shopkeeperId"
          component={ShopTransactions}
        />

        <Route exact path="/shop-payments" component={ShopPaymentsPage} />
        <Route exact path="/disbursement" component={DisbursementsPage} />
        <Route exact path="/delete" component={DeletePage} />
        <Route exact path="/treasury" component={TreasuryPage} />
        <Route exact path="/reports" component={ReportsPage} />
        <Route
          exact
          path="/bureau-documents_"
          component={BureauDocumentsPage}
        />
        <Route exact path="/buy-dealer" component={BuyDealer} />
        <Route
          exact
          path="/buy-dealer/detail/:creditLineId/:accountId/:purchaseId"
          component={DetailBuyDealer}
        />
        <Route
          exact
          path="/buy-dealer/detail/:creditLineId/:accountId/:purchaseId/:voucherId/:supplierId/metadata-dealer"
          component={MetadataBuyDealer}
        />
        <Route exact path="/dealerTreasury" component={DisbursementDealer} />
        <Route exact path="/extensions" component={CreditExtensions} />
        <Route
          exact
          path="/extensions/create"
          component={CreditExtensionForm}
        />
        <Route exact path="/review-voucher" component={ReviewVoucher} />
        <Route
          exact
          path="/review-voucher/detail/:creditLineId/:accountId"
          component={DetailReviewVouchers}
        />
        <Route
          exact
          path="/review-voucher/detail/:creditLineId/:accountId/:voucherId/:supplierId/metadata-voucher"
          component={MetadataReviewVouchers}
        />

        <Route exact path="/shop" component={ShopPage} />
        <Route
          exact
          path="/shop/submenu/:creditLineId/:shopId"
          component={SubMenuPage}
        />
        <Route
          exact
          path="/extensions/details/:extensionId/:shopId"
          component={ExtensionDetails}
        />
        <Route
          exact
          path="/shop/settlements/:resourceId/transactions/:shopkeeperId"
          component={ShopTransactions}
        />
        <Route
          exact
          path="/shop/collections/:resourceId/transactions/:shopkeeperId"
          component={ShopTransactions}
        />

        <Route exact path="/wholesalers" component={WareHouse} />
        <Route exact path="/wholesalers/register" component={WholesalerPage} />

        <Route path="**" component={NotFoundPageC} />
      </Switch>
    </App>
  </Router>
);

export default RouterR;
