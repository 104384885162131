import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  metadataContainer: {
    width: "1134px",
    height: "823px",
    borderTop: "2px solid #E8E8FC",
    borderBottom: "2px solid #E8E8FC",
    borderRight: "2px solid #E8E8FC",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "1094px",
    margin: "10px 20px 20px 20px",
  },
  title: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#37465A",
    textDecorationLine: "underline",
  },
  editButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  headerEndText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#3050FF",
    marginRight: "10px",
  },
  pencilIconStyle: {
    width: "18px",
    height: "18px",
  },
  infoTicketContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "1094px",
    margin: "0px 20px 20px 20px",
  },
  infoTicketTexts: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    width: "258.5px",
    height: "49px",
  },
  infoTicketTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858D9C",
    marginLeft: "5px",
  },
  infoTicketText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#11152D",
    marginLeft: "25px",
  },
  tableContainer: {
    width: "1094px",
    margin: "0px 20px 20px 20px",
    flexGrow: 1,
    overflow: "auto",
  },
  tableHead: {
    width: "100%",
    backgroundColor: "#F8F9F9",
  },
  titleTable: {
    color: "#858d9c",
    fontSize: 12,
    textAlign: "center",
  },
  textCellTable: {
    color: "#3a3b3f",
    fontSize: 11,
    textAlign: "center",
  },
  centerContentStyle: {
    textAlign: "center",
  },
  buttonTopSave: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#3050FF",
    marginRight: "15px",
    borderRadius: "8px",
  },
  buttonTopCancel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#3050FF",
    borderRadius: "8px",
  },
  textButtonTop: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    color: "#FFF",
    marginRight: "5px",
  },
  addItemContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderTop: "1px solid #F1F1F1",
  },
  addItemButton: {
    display: "flex",
    backgroundColor: "transparent",
    width: "100%",
  },
  addItemText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    marginRight: "5px",
    color: "#1D2632",
  },
  itemsFoundTitle: {
    fontSize: "14px",
    color: "#11152D",
    marginLeft: "5px",
    marginTop: "25px",
    fontWeight: "700",
    textDecorationLine: "underline",
  },
  containerRejected: {
    backgroundColor: "#FFE8E8",
    padding: 5,
    borderRadius: 5,
    marginLeft: 20,
    height: 20,
  },
  statusText: {
    color: "#3A4760",
    fontSize: 12,
    fontWeight: 400,
  },
  containerApproved: {
    backgroundColor: "#E9F8E7",
    padding: 5,
    borderRadius: 5,
    marginLeft: 20,
    height: 20,
  },
}));

export default useStyles;
